import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import a118 from "../photos/a118.png";
import "./styles.css";

export default function Anthro118() {

  return (
    <div className="main">
        <img src={a118} alt="tracing genetic relationships"></img>
        <h5>
            References:
        </h5>
        <p>
        Fukumine, T., Hanihara, T., Nishime, A., &amp; Ishida, H. (2006), Nonmetric cranial variation of early modern human skeletal remains from Kumejima, Okinawa and the peopling of the Ryukyu Islands. Anthropological Science, 114(2), 141-151.
        </p>
        <p>
        Temple, D. H., &amp; Larsen, C. S. (2007). Dental caries prevalence as evidence for agriculture and subsistence variation during the Yayoi period in prehistoric Japan: biocultural interpretations of an economy in transition. American Journal of Physical Anthropology, 134(4), 501-512.
        </p>
        <p>
        Hammer, M. F., Karafet, T. M., Park, H., Omoto, K., Harihara, S., Stoneking, M., &amp; Horai, S. (2006). Dual origins of the Japanese: common ground for hunter-gatherer and farmer Y chromosomes. Journal of Human Genetics, 51(1), 47-58.
        </p>
        <p>
        Hanihara, K. (1991). Dual structure model for the population history of the Japanese. Nichibunken Japan Review,2(1), 1-33.
        </p>
        <p>
        Karafet, T., Xu, L., Du, R., Wang, W., Feng, S., Wells, R. S., ... &amp; Hammer, M. F. (2001). Paternal population history of East Asia: sources, patterns, and microevolutionary processes. The American Journal of Human Genetics, 69(3), 615-628.
        </p>
        <p>
        Nagaoka, T., Abe, M., &amp; Shimatani, K. (2012), Estimation of mortality profiles from non-adult human skeletons in Edo-period Japan. Anthropological Science, 120(2), 115-128.
        </p>
        <p>
        Shi, H., Zhong, H., Peng, Y., Dong, Y. L., Qi, X. B., Zhang, F., ... &amp; Wells, R. S. (2008). Y chromosome evidence of earliest modern human settlement in East Asia and multiple origins of Tibetan and Japanese populations. BMC biology, 6(1), 45.
        </p>
        <p>
        Temple, D. H. (2010). Patterns of systemic stress during the agricultural transition in prehistoric Japan. American Journal of Physical Anthropology, 142(1), 112-124.
        </p>
        <p>
        Xue, Y., Zerjal, T., Bao, W., Zhu, S., Shu, Q., Xu, J., ... &amp; Yang, H. (2006). Male demography in East Asia: a north–south contrast in human population expansion times. Genetics, 172(4), 2431-2439.
        </p>
        <p>
        Yamaguchi-Kabata, Y., Nakazono, K., Takahashi, A., Saito, S., Hosono, N., Kubo, M., ... &amp; Kamatani, N. (2008). Japanese population structure, based on SNP genotypes from 7003 individuals compared to other ethnic groups: effects on population-based association studies. The American Journal of Human Genetics, 83(4), 445-456.
        </p>
    </div>
  );
}